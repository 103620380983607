import React from 'react'
import ChangePassContainer from '../components/organisms/changePassContainer/changePassContainer'
import Layout from '../shared/components/organisms/layout/layout'
import SEO from '../shared/components/seo'
import useBrowserMode from '../shared/hooks/useBrowserMode'
import useHomeLinkMode from '../shared/hooks/useHomeLinkMode'
import useLocaleMode from '../shared/hooks/useLocaleMode'
import useMobileMode from '../shared/hooks/useMobileMode'
import { sessionStorage } from '../shared/state/utils/storage'
import useSessionState from '../shared/hooks/useSessionState'

const CambioContrasena = props => {
	const { locale } = useLocaleMode()
	const { isMobile, currentPage } = useMobileMode()
	const { isBrowser } = useBrowserMode()
	const { homeLink } = useHomeLinkMode()
	const { actualStorage } = useSessionState()
	const temp = isBrowser && sessionStorage.getItem('__tmp')

	return (
		<>
			{temp !== null && temp !== undefined ? (
				<Layout
					isMobile={isMobile}
					isChangePassword={true}
					currentPage={currentPage}
				>
					<SEO lang={locale} />
					<ChangePassContainer
						type={1}
						tempPass={temp}
						actualStorage={actualStorage}
					/>
				</Layout>
			) : (
				isBrowser && window.location.replace(homeLink + '404')
			)}
		</>
	)
}

export default CambioContrasena
